'use client';

import classNames from 'classnames';
import { ChevronDown } from 'lucide-react';
import { memo, useCallback, useMemo, useState } from 'react';
import { TableCell } from './table-cell';
import { TableRowExpander } from './table-row-expander';
import { TableCellOptions, TableProps, TableRowOptions } from './types';

export type TableRowProps<T> = Pick<
  TableProps<T>,
  | 'columns'
  | 'compact'
  | 'className'
  | 'cellClassName'
  | 'stickyLeftClassName'
  | 'expandable'
  | 'expandableRow'
  | 'expandableData'
  | 'expandedRowClassName'
  | 'rowKey'
  | 'onRowMouseEnter'
  | 'onRowMouseLeave'
  | 'onRowClick'
> & {
  rowData: T;
  rowIndex: number;
  rowSpans: number[];
  cellVisibilities: boolean[];
  isExpandedRow?: boolean;
};

function _TableRow<T>(props: TableRowProps<T>) {
  const {
    rowKey,
    columns,
    compact,
    rowData,
    rowIndex,
    rowSpans,
    cellVisibilities,
    className,
    cellClassName,
    stickyLeftClassName,
    expandable,
    expandableRow,
    isExpandedRow,
    onRowMouseEnter: onMouseEnter,
    onRowMouseLeave: onMouseLeave,
    onRowClick: onClick,
  } = props;
  const [expanded, setExpanded] = useState<boolean>(false);
  const handleExpandToggle = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);
  const rowOptions = useMemo<TableRowOptions<T>>(
    () => ({
      rowData,
      rowIndex,
    }),
    [rowData, rowIndex]
  );
  const handleMouseEnter = useCallback(() => {
    onMouseEnter?.(rowOptions);
  }, [rowOptions]);
  const handleMouseLeave = useCallback(() => {
    onMouseLeave?.(rowOptions);
  }, [rowOptions]);
  const handleClick = useCallback(() => {
    onClick?.(rowOptions);
  }, [rowOptions]);
  const isExpandableRow = expandableRow?.({ rowData, rowIndex }) ?? true;

  return (
    <>
      <tr
        className={className}
        data-key={rowKey?.(rowOptions)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        {expandable ? (
          <td
            className={classNames('cursor-pointer align-middle border-r border-r-zinc-300', {
              'bg-zinc-100 hover:bg-zinc-200 border-b border-b-zinc-200': !expanded,
              'bg-zinc-300 hover:bg-zinc-400': expanded,
            })}
            width="1%"
            onClick={isExpandableRow ? handleExpandToggle : undefined}
          >
            <div className="w-7 lg:w-9 flex justify-center">
              <ChevronDown size={19} className={isExpandableRow ? '' : 'text-gray-300'} />
            </div>
          </td>
        ) : isExpandedRow ? (
          <td width="1%" className="bg-zinc-300" />
        ) : null}
        {columns.map((column, columnIndex) => {
          const rowSpan = rowSpans[columnIndex];
          const options: TableCellOptions<T> = {
            column,
            columnIndex,
            rowData,
            rowIndex,
            rowSpan,
          };
          const isHidden = !cellVisibilities[columnIndex];

          return isHidden ? null : (
            <TableCell
              key={(column.key as string) ?? columnIndex}
              className={
                typeof cellClassName === 'function' ? cellClassName(options) : cellClassName
              }
              column={column}
              columnIndex={columnIndex}
              compact={compact}
              isFirstColumn={columnIndex === 0}
              isLastColumn={columnIndex === columns.length - 1}
              rowData={rowData}
              rowIndex={rowIndex}
              rowSpan={rowSpan}
              stickyLeftClassName={stickyLeftClassName}
            />
          );
        })}
      </tr>
      {expanded ? <TableRowExpander<T> {...props} /> : null}
    </>
  );
}

export const TableRow = memo(_TableRow) as typeof _TableRow;
