'use client';

import { useRemote } from '@spikemark/shared-hooks';
import { memo } from 'react';
import { LoadingSpinner } from '../loading-spinner';
import { TableRowProps } from './table-row';
import { TableRowGroup } from './table-row-group';

type TableRowExpanderProps<T> = TableRowProps<T>;

function _TableRowExpander<T>(props: TableRowExpanderProps<T>) {
  const {
    columns,
    expandable,
    expandableData,
    expandedRowClassName,
    rowData,
    rowIndex,
    ...rowGroupProps
  } = props;
  const {
    data: expandedData,
    error,
    isLoading,
  } = useRemote<T[], { rowData: T; rowIndex: number }>(expandableData, {
    params: {
      rowData,
      rowIndex,
    },
    reset: false,
  });

  return isLoading || error ? (
    <tr className={expandedRowClassName}>
      <td />
      <td colSpan={columns.length}>
        {error ? `There was an issue loading this data: ${error.message}` : <LoadingSpinner />}
      </td>
    </tr>
  ) : (
    <TableRowGroup<T>
      columns={columns}
      data={expandedData}
      isExpandedRowGroup
      rowClassName={expandedRowClassName}
      {...rowGroupProps}
    />
  );
}

export const TableRowExpander = memo(_TableRowExpander) as typeof _TableRowExpander;
