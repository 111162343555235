'use client';

import { memo, useMemo } from 'react';
import { TableEmptyRow } from './table-empty-row';
import { TableRow } from './table-row';
import { TableProps } from './types';

export type TableRowGroupProps<T> = Pick<
  TableProps<T>,
  | 'columns'
  | 'compact'
  | 'data'
  | 'rowKey'
  | 'emptyMessage'
  | 'emptyRowClassName'
  | 'rowClassName'
  | 'rowFilter'
  | 'cellClassName'
  | 'stickyLeftClassName'
  | 'expandable'
  | 'expandableRow'
  | 'expandableData'
  | 'expandedRowClassName'
  | 'onRowMouseEnter'
  | 'onRowMouseLeave'
  | 'onRowClick'
> & {
  isExpandedRowGroup?: boolean;
};

function _TableRowGroup<T>({
  columns,
  compact,
  data,
  rowKey,
  rowFilter,
  emptyMessage,
  emptyRowClassName,
  rowClassName,
  cellClassName,
  stickyLeftClassName,
  expandable,
  expandableRow,
  expandableData,
  expandedRowClassName,
  isExpandedRowGroup,
  onRowMouseEnter,
  onRowMouseLeave,
  onRowClick,
}: TableRowGroupProps<T>) {
  const { rowSpanMatrix, cellVisibilityMatrix } = useMemo(() => {
    const rowSpanMatrix =
      data?.map((rowData, rowIndex) =>
        columns.map(
          (column, columnIndex) =>
            column?.rowSpan?.({
              column,
              columnIndex,
              rowData,
              rowIndex,
            }) ?? 1
        )
      ) ?? [];
    const cellVisibilityMatrix: boolean[][] = rowSpanMatrix.map((row) =>
      row.map((value, columnIndex) => (value && !columns[columnIndex].hidden ? true : false))
    );

    return {
      rowSpanMatrix,
      cellVisibilityMatrix,
    };
  }, [columns, data]);

  return (
    <>
      {data && data.length > 0 ? (
        data.map((rowData, rowIndex) => {
          if (
            rowFilter &&
            !rowFilter({
              rowData,
              rowIndex,
            })
          ) {
            return null;
          }

          const key =
            rowKey?.({
              rowData,
              rowIndex,
            }) ?? rowIndex;

          return (
            <TableRow
              key={key}
              data-key={key}
              className={
                typeof rowClassName === 'function'
                  ? rowClassName({
                      rowData,
                      rowIndex,
                    })
                  : rowClassName
              }
              cellClassName={cellClassName}
              cellVisibilities={cellVisibilityMatrix[rowIndex]}
              columns={columns}
              compact={compact}
              rowData={rowData}
              rowIndex={rowIndex}
              rowKey={rowKey}
              rowSpans={rowSpanMatrix[rowIndex]}
              stickyLeftClassName={stickyLeftClassName}
              expandable={expandable}
              expandableRow={expandableRow}
              expandableData={expandableData}
              expandedRowClassName={expandedRowClassName}
              isExpandedRow={isExpandedRowGroup}
              onRowMouseEnter={onRowMouseEnter}
              onRowMouseLeave={onRowMouseLeave}
              onRowClick={onRowClick}
            />
          );
        })
      ) : (
        <TableEmptyRow
          className={emptyRowClassName}
          colSpan={columns.length + (isExpandedRowGroup ? 1 : 0)}
          emptyMessage={emptyMessage}
          expandable={expandable}
        />
      )}
    </>
  );
}

export const TableRowGroup = memo(_TableRowGroup) as typeof _TableRowGroup;
